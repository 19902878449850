import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  Button,
  Modal,
  Form,
  ModalHeader,
  ModalBody
} from "reactstrap";
import classnames from "classnames";

//redux
import { useSelector } from "react-redux";
import withRouter from "Components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "Components/Common/Breadcrumb";

import avatar from "../../assets/images/users/user_profile.svg";
import { createSelector } from "reselect";
import Availability from "pages/Onboarding/Availability";
import Calendar from "pages/Onboarding/Calendar";
import { InfoForm } from "pages/Onboarding/InfoForm";
import { MentorSurvey } from "pages/Onboarding/MentorSurvey";
import { useDispatch } from "react-redux";
import { fetchMentorSubmittedSurvey } from "slices/mentor/thunk";
import { MenteeSurvey } from "pages/Onboarding/MenteeSurvey";
import { fetchMenteeSubmittedSurvey } from "slices/mentee/thunk";
import { useFormik } from "formik";
import * as Yup from "yup";
import { uploadProfilePicture } from "slices/onboarding/thunk";
import ChangePassword from "./ChangePassword";

const UserProfile = () => {
  document.title = "Profile | Mentorgain";

  const dispatch = useDispatch<any>();
  const [show, setShow] = useState<boolean>(false);

  const selectProperties = createSelector(
    (state: any) => state.Global,
    (profile) => ({
      user: profile.user,
      error: profile.error,
      success: profile.success,
    })
  );

  const selectProfileProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
    })
  );

  const { user } = useSelector(selectProfileProperties);

  const fetchMentorSurveySelector = createSelector(
    (state: any) => state.Mentor,
    (mentor) => ({
      submittedMentorSurvey: mentor.submittedMentorSurvey,
    })
  );

  const fetchMenteeSurveySelector = createSelector(
    (state: any) => state.Mentee,
    (mentor) => ({
      submittedMenteeSurvey: mentor.submittedMenteeSurvey,
    })
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  

  useEffect(() => {
    dispatch(fetchMentorSubmittedSurvey());
    dispatch(fetchMenteeSubmittedSurvey());
  }, [dispatch]);

  const { error, success } = useSelector(selectProperties);
  const { submittedMentorSurvey } = useSelector(fetchMentorSurveySelector);
  const { submittedMenteeSurvey } = useSelector(fetchMenteeSurveySelector);

  const [activeTab, setActiveTab] = useState<string>("profile");
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: null,
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required("Please select a file"),
    }),

    onSubmit: (values: any) => {
      dispatch(uploadProfilePicture(values)).then(() => {
        setShow(false);
        window.location.href = "/profile";
      });
    },
  });

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    validation.setFieldValue("file", file);
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={show} toggle={() => setShow(false)} centered={true}>
        <ModalHeader toggle={() => setShow(false)}>
          Change Profile Picture
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <FormGroup>
              <Form onSubmit={validation.handleSubmit} className="d-flex justify-content-center">
                <Input
                  type="file"
                  name="file"
                  accept="image/*"
                  id="fileUpload"
                  onChange={handleFileChange}
                  onBlur={validation.handleBlur}
                  style={{ width: "60%", display: "inline-block" }}
                />
                <Button color="secondary" type="submit" disabled={validation.isSubmitting || !validation.values.file} className="ms-3">
                  {validation.isSubmitting ? "Uploading..." : "Upload"}
                </Button>
              </Form>
            </FormGroup>
            <div className="my-3 text-center">
              <h5>Preview:</h5>
              <div className="w-100 h-50 d-flex justify-content-center">
                {previewImage && <img src={previewImage} className="w-25 h-25 img-thumbnail rounded" alt="Preview" style={{ maxHeight: "100%" }} />}
              </div>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Col xl={12} lg={12}>
          <Card>
            <Nav tabs className="nav-tabs-custom justify-content-center pt-2" role="tablist">
              <NavItem>
                <NavLink
                  to="#"
                  className={classnames({ active: activeTab === "profile" })}
                  onClick={() => { setActiveTab("profile") }}
                >
                  Profile
                </NavLink>
              </NavItem>
              {(user?.type === 'BOTH' || user?.type === 'MENTOR') && (
                <>
                  <NavItem>
                    <NavLink
                      to="#"
                      className={classnames({ active: activeTab === "availability" })}
                      onClick={() => { setActiveTab("availability") }}
                    >
                      My Availability
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      className={classnames({ active: activeTab === "calendar" })}
                      onClick={() => { setActiveTab("calendar") }}
                    >
                      Calendar
                    </NavLink>
                  </NavItem>
                </>
              )}

              <NavItem>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle nav caret>
                    Preferences
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      className={classnames({ active: activeTab === "menteeOnboarding" })}
                      onClick={() => { setActiveTab("menteeOnboarding") }}
                    >
                      {user.menteeFields ? "My Mentee Preferences" : "Become a Mentee"}
                    </DropdownItem>

                    <DropdownItem
                      className={classnames({ active: activeTab === "mentorOnboarding" })}
                      onClick={() => { setActiveTab("mentorOnboarding") }}
                    >
                       {user.mentorFields ? "My Mentor Preferences" : "Become a Mentor"}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>

              <NavItem>
                <NavLink
                  to="#"
                  className={classnames({
                    active: activeTab === "changePassword",
                  })}
                  onClick={() => {
                    setActiveTab("changePassword");
                  }}
                >
                  Change Password
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="p-4" activeTab={activeTab}>
              <TabPane tabId="profile">
                <Container fluid>
                  <Breadcrumb title="Mentorgain" breadcrumbItem="Profile" />

                  <Row>
                    <Col lg="12">
                      {error && error ? <Alert color="danger">{error}</Alert> : null}
                      {success ? <Alert color="success">{success}</Alert> : null}

                      <Card>
                        <CardBody>
                          <div className="d-flex">
                            <div className="me-3">
                              <img
                                src={user.avatarURL || avatar}
                                alt=""
                                className="avatar-md rounded-circle"
                              />
                              <i
                                className="fas fa-edit text-muted"
                                style={{ cursor: "pointer", position: 'absolute', bottom: '20px', left: '80px' }}
                                onClick={() => setShow(true)}
                              />
                            </div>
                            <div className="flex-grow-1 align-self-center">
                              <div className="text-muted">
                                <h5>{`${user.firstName} ${user.lastName}` || "admin"}</h5>
                                <p className="mb-1">{user.email || "admin@gmail.com"}</p>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <h4 className="card-title mb-4">User Profile</h4>
                </Container>
                <InfoForm onProfilePage />
              </TabPane>
              {(user?.type === 'BOTH' || user?.type === 'MENTOR') && (
                <>
                  <TabPane tabId="availability">
                    <Availability showCalendar={false} onProfilePage={true} />
                  </TabPane>
                  <TabPane tabId="calendar">
                    <Calendar />
                  </TabPane>
                </>
              )}
          
              <TabPane tabId="menteeOnboarding">
                {activeTab === "menteeOnboarding" ? (
                  <MenteeSurvey submittedSurvey={submittedMenteeSurvey} profilePage={true} />
                ) : (
                  <Spinner />
                )}
              </TabPane>
              

              <TabPane tabId="mentorOnboarding">
                {activeTab === "mentorOnboarding" ? (
                  <MentorSurvey submittedSurvey={submittedMentorSurvey} profilePage={true} />
                ):(
                  <Spinner />
                )}
              </TabPane>

              <TabPane tabId="changePassword">
                <ChangePassword />
              </TabPane>
            </TabContent>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
