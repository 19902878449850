import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  rejectRequestedSession,
  cancelScheduledSession,
  fetchRequestedSessions,
  cancelRequestedSession,
} from "slices/session/thunk";
import SessionList from "./SessionList";
import RescheduleSessionModal from "Components/Common/rescheduleSessionModal";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useAppDispatch } from "slices";
import { useLocation } from "react-router-dom";
import { setPerPageForSessions } from "slices/session/reducer";
import { SESSION_OR_REQUEST_RESCHEDULED } from "../../helpers/events";
import Emitter from "../../helpers/emitter";

const RequestedSessions = (props: any) => {
  const { type, contactId } = props;
  const [showRescheduleModal, setShowRescheduleModal] =
    useState<boolean>(false);
  const [declineModal, setDeclineModal] = useState<boolean>(false);
  const [selectedSession, setSelectedSession] = useState<any>(null);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [isMentor, setIsMentor] = useState<boolean>(false);
  const [reasonForDecline, setReasonForDecline] = useState<string>("");
  const [isRequestCancel, setIsRequestCancel] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      dispatch(setPerPageForSessions(4));
    } else {
      dispatch(setPerPageForSessions(10));
    }
    dispatch(fetchRequestedSessions(contactId));
    Emitter.on(SESSION_OR_REQUEST_RESCHEDULED, () => {
      dispatch(fetchRequestedSessions(contactId))
    });
    return () => {
      Emitter.off(SESSION_OR_REQUEST_RESCHEDULED, () => {
        dispatch(fetchRequestedSessions(contactId))
      });
    };
  }, [dispatch, location, contactId]);

  const selectRequestedSessions = createSelector(
    (state: any) => state.SessionList,
    (session) => ({
      requestedSessions: session.requestedSessions,
    })
  );

  const { requestedSessions } = useSelector(selectRequestedSessions);
  const pendingRequests = requestedSessions.filter(
    (request: any) => request.status === "PENDING"
  );

  const handleRescheduleModalOpen = (id: any, userId: any, isMentor: any) => {
    setSelectedSession(id);
    setShowRescheduleModal(true);
    setSelectedUserId(userId);
    setIsMentor(isMentor);
  };

  const handleOpenModal = (id: any) => {
    setSelectedSession(id);
  };

  const handleDeclineRequest = (id: any, type: any) => {
    if (type === "DECLINE") {
      setIsRequestCancel(true);
    }
    setSelectedSession(id);
    setDeclineModal(true);
  };

  const handleRequestReject = () => {
    dispatch(
      rejectRequestedSession({
        requestId: selectedSession,
        status: "reject",
        reason: reasonForDecline,
        contactId,
      })
    );
    setDeclineModal(false);
  };

  const handleRequestCancel = () => {
    dispatch(
      cancelRequestedSession({
        requestId: selectedSession,
        reason: reasonForDecline,
        contactId,
      })
    );
    setDeclineModal(false);
  };

  const handleScheduledCancel = () => {
    if (selectedSession) {
      dispatch(
        cancelScheduledSession({
          requestId: selectedSession,
          reason: reasonForDecline,
          contactId,
        })
      );
    }
    setDeclineModal(false);
  };

  const toggleRescheduleModal = () => {
    setShowRescheduleModal(!showRescheduleModal);
  };

  return (
    <>
      {selectedSession && (
        <>
          <RescheduleSessionModal
            showModal={showRescheduleModal}
            selectedSession={selectedSession}
            selectedUserId={selectedUserId}
            toggleRescheduleModal={toggleRescheduleModal}
            rescheduleType="REQUESTED"
            isMentor={isMentor}
          />
          <Modal
            isOpen={declineModal}
            toggle={() => setDeclineModal(!declineModal)}
            centered
          >
            <ModalHeader toggle={() => setDeclineModal(!declineModal)}>
              Reason for{" "}
              {isRequestCancel
                ? "Cancel"
                : type === "REQUESTED"
                ? "decline"
                : "cancel"}
            </ModalHeader>
            <ModalBody>
              <Input
                type="textarea"
                value={reasonForDecline}
                onChange={(e) => setReasonForDecline(e.target.value)}
              ></Input>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <Button
                  className="btn btn-sm btn-danger"
                  onClick={
                    isRequestCancel
                      ? handleRequestCancel
                      : type === "REQUESTED"
                      ? handleRequestReject
                      : handleScheduledCancel
                  }
                >
                  {isRequestCancel
                    ? "Cancel"
                    : type === "REQUESTED"
                    ? "Decline"
                    : "Cancel"}{" "}
                  Request
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
      {pendingRequests?.length === 0 ? (
        <div className="text-center">No sessions found</div>
      ) : (
        <SessionList
          listData={pendingRequests || []}
          openModal={handleOpenModal}
          type="REQUESTED"
          handleRescheduleModalOpen={handleRescheduleModalOpen}
          handleDeclineRequest={handleDeclineRequest}
          contactId={contactId}
        />
      )}
    </>
  );
};

export default RequestedSessions;
