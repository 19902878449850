import { userAcceptInviteSuccess, userAcceptInviteError } from "./reducer"
import { APIClient } from "../../../helpers/api_helper";
const api = new APIClient();

export const acceptInvite = (password: any, token: string, history: any) => async (dispatch: any) => {
  try {
    const data = await api.create('/api/v1/onboarding/accept-invite', {
      password: password,
      token: token
    });

      if (token === null) {
        dispatch(userAcceptInviteError("Token is missing"));
      } else if (data && data["status"]) {
        dispatch(
          userAcceptInviteSuccess(
            "Invite Accepted Successfully. Redirecting to Login Page."
          )
        );
      }
      history("/login");
    } catch (error: any) {
      dispatch(userAcceptInviteError(error.message));
    }
  };
