import classnames from "classnames";
import { useState, useMemo } from "react";
import {
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap";
import RequestedSessions from "../Sessions/RequestedSessions";
import AcceptedSessions from "../Sessions/AcceptedSessions";
import { useNavigate } from "react-router-dom";

const SessionHistory = () => {
  const navigate = useNavigate();

  const [activeTab, toggleTab] = useState<any>("1");

  const requestedSessions = useMemo(() => {
    return <RequestedSessions type="REQUESTED" />;
  }, []);

  const scheduledSessions = useMemo(() => {
    return <AcceptedSessions type="SCHEDULED" />;
  }, []);

  return (
    <div className="col-xs-12">
      <Card        
        style={{
          height: "450px",
          overflowY: "auto",        
        }}
      >
        <CardBody className="py-3 px-2">
          <Nav tabs className="nav-tabs-custom" role="tablist">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                <span className="d-sm-block">REQUESTED</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "SCHEDULED",
                })}
                onClick={() => {
                  toggleTab("SCHEDULED");
                }}
              >
                <span className="d-sm-block">UPCOMING</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="pt-3 text-muted">
            <TabPane tabId="1">{activeTab === "1" && requestedSessions}</TabPane>
            <TabPane tabId="SCHEDULED">
              {activeTab === "SCHEDULED" && scheduledSessions}
            </TabPane>
          </TabContent>
          <div
            style={{
              marginTop: "6px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => navigate("/sessions")}
              className="btn btn-primary"
            >
              See More
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default SessionHistory;
