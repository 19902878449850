import classnames from "classnames";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NavLink, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import RequestedSessions from "./RequestedSessions";
import AcceptedSessions from "./AcceptedSessions";
import { setPerPageForSessions } from "slices/session/reducer";
import { useAppDispatch } from "slices";
import { useSearchParams } from "react-router-dom";

const Sessions = () => {
  const { contactId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPerPageForSessions(10));
  }, [dispatch]);

  const tabFromUrl = (searchParams.get("tab") || "REQUESTED").toUpperCase();
  const [activeTab, setActiveTab] = useState(tabFromUrl);

  useEffect(() => {
    if (tabFromUrl !== activeTab) {
      setActiveTab(tabFromUrl);
    }   
  }, [tabFromUrl, activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  return (
    <>
      <div className="page-content">
        <Nav tabs className="nav-tabs-custom" role="tablist">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "REQUESTED",
              })}
              onClick={() => {
                handleTabClick("REQUESTED");
              }}
            >

              <span className="d-sm-block">REQUESTED</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "SCHEDULED",
              })}
              onClick={() => {
                handleTabClick("SCHEDULED");
              }}
            >
              <span className="d-sm-block">SCHEDULED</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "COMPLETED",
              })}
              onClick={() => {
                handleTabClick("COMPLETED");
              }}
            >
              <span className="d-sm-block">COMPLETED</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "CANCELED",
              })}
              onClick={() => {
                handleTabClick("CANCELED");
              }}
            >
              <span className="d-sm-block">CANCELLED</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="p-3 text-muted">
          <TabPane tabId="REQUESTED">{activeTab === "REQUESTED" && <RequestedSessions contactId={ contactId } type="REQUESTED" />}</TabPane>
          <TabPane tabId="SCHEDULED">
            {activeTab === "SCHEDULED" && <AcceptedSessions contactId={ contactId } type="SCHEDULED" />}
          </TabPane>
          <TabPane tabId="COMPLETED">
            {activeTab === "COMPLETED" && <AcceptedSessions contactId={ contactId } type="COMPLETED" />}
          </TabPane>
          <TabPane tabId="CANCELED">
            {activeTab === "CANCELED" && <AcceptedSessions contactId={ contactId } type="CANCELED" />}
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Sessions;
