import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const api = new APIClient();

export const getAnalyticsCount = createAsyncThunk(
  "analytics/getCount",
  async () => {
    try {
      const response = await api.get("/api/v1/analytics/counts");

      return response;
    } catch (error) {
      console.error("Error fetching analytics count:", error);
      throw error;
    }
  }
);

export const getSessionByDuration = createAsyncThunk(
  "analytics/getSessionsByDuration",
  async () => {
    try {
      const response = await api.get(
        "/api/v1/analytics/sessions/by-duration/monthly"
      );
      return response?.totalSessions;
    } catch (error) {
      console.error("Error fetching sessions by duration:", error);
      throw error;
    }
  }
);

export const getSessionByDay = createAsyncThunk(
  "analytics/getSessionsByDay",
  async () => {
    try {
      const response = await api.get("/api/v1/analytics/sessions/by-multiple");
      return response;
    } catch (error) {
      console.error("Error fetching sessions by day:", error);
      throw error;
    }
  }
);

export const getUserAnalytics = createAsyncThunk(
  "analytics/getUsers",
  async (filterData: any) => {
    try {
      const { filterQuery, perPage, page } = filterData;
      filterQuery["perPage"] = perPage;
      filterQuery["page"] = page;
      const response = await api.get(`/api/v1/analytics/users`, filterQuery);
      return response;
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      toast.error("Failed to filter the data. Please try again.");
      throw error;
    }
  }
);

export const getUserAnalyticsType = createAsyncThunk(
  "analytics/getUsersType",
  async (userType: any) => {
    try {
      const response = await api.get(
        `/api/v1/analytics/count/byUserType`,
        userType
      );
      return response;
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      throw error;
    }
  }
);

export const getUserAnalyticsRating = createAsyncThunk(
  "analytics/rating",
  async () => {
    try {
      const response = await api.get(`/api/v1/analytics/user/rating`);
      return response;
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      throw error;
    }
  }
);

export const getUserReviews = createAsyncThunk("analytics/review", async () => {
  try {
    const response = await api.get(`/api/v1/analytics/user/review`, {
      perPage: 3,
    });
    return response;
  } catch (error) {
    console.error("Error fetching user analytics:", error);
    throw error;
  }
});

export const getUserSessionByMonth = createAsyncThunk(
  "analytics/sessionByMonth",
  async () => {
    try {
      const response = await api.get(
        `/api/v1/analytics/user/session/by-month`,
        {
          perPage: 3,
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      throw error;
    }
  }
);

export const getMenteeAvgScore = createAsyncThunk(
  "analytics/menteeAvgScore",
  async () => {
    try {
      const response = await api.get(`/api/v1/analytics/avg-score/mentee`);
      return response;
    } catch (error) {
      console.error("Error fetching mentee average score:", error);
      throw error;
    }
  }
);

export const getMentorAvgScore = createAsyncThunk(
  "analytics/mentorAvgScore",
  async () => {
    try {
      const response = await api.get(`/api/v1/analytics/avg-score/mentor`);
      return response;
    } catch (error) {
      console.error("Error fetching mentor average score:", error);
      throw error;
    }
  }
);
