import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { setSessions } from "./reducer";
import { RootState } from "slices";

const api = new APIClient();

export const requestSession = (data: any) => async (dispatch: any) => {
  try {
    const response = await api.create("/api/v1/sessions/request", {
      participant1: data.mentorId,
      date: data.date,
      time: data.time,
      mode: data.mode,
      skills: data.skills,
      notes: data.notes,
    });
    if (response) {
      toast.success("Session requested successfully", { autoClose: 2000 });
    }
  } catch (error) {
    toast.error("Session couldn't be booked. Please try again", { autoClose: 2000 });
    console.error(error);
  }
};

export const rescheduleRequest = (data: any) => async (dispatch: any) => {
  try {
    const response = await api.create(
      `/api/v1/sessions/request/${data.requestId}/reschedule`,
      {
        date: data.date,
        time: data.time,
        reason: data.reason,
      }
    );
    if (response) {
      toast.success("Session rescheduled request successfully", {
        autoClose: 2000,
      });
    }
  } catch (error) {
    toast.error("Failed to reschedule session", { autoClose: 2000 });
    console.error(error);
  }
};

export const rescheduleSession = (data: any) => async (dispatch: any) => {
  try {
    const response = await api.create(
      `/api/v1/sessions/${data.requestId}/reschedule`,
      {
        date: data.date,
        time: data.time,
        reason: data.reason,
      }
    );
    if (response) {
      toast.success("Session rescheduled request successfully", {
        autoClose: 2000,
      });
    }
  } catch (error) {
    toast.error("Failed to reschedule session", { autoClose: 2000 });
    console.error(error);
  }
};

export const fetchRequestedSessions = createAsyncThunk<
  any,
  string,
  { state: RootState }
>("session/fetchRequestedSessions", async (contactId: string | undefined, { getState }) => {
  try {
    const { page, perPage } = getState()?.SessionList || {};
    const contactIdParam = contactId ? { contactId } : {};
    const response = await api.get("/api/v1/my/sessions-requests", {
      perPage,
      page,
      status: "PENDING",
      ...contactIdParam,
    });
    return response
  } catch (error) {
    console.error(error);
  }
});

export const fetchMySessions = createAsyncThunk<
  any,
  { status: string; contactId?: string },
  { state: RootState }
>("session/fetchMySessions", async ({ status, contactId }, { getState }) => {
  try {
    const { page, perPage } = getState()?.SessionList || {};
    const params: any = {
      sessionStatus: status,
      perPage,
      page,
    };
    if (contactId !== undefined) {
      params.contactId = contactId;
    }
    const response = await api.get("/api/v1/my/sessions", params);
    return response;
  }
   catch (error) {
    console.error(error);
  }
});

export const fetchCanceledSessions = (contactId: string) => async (dispatch: any) => {
  try {
    const requestedCanceled = await api.get(`/api/v1/my/sessions-requests?status=REJECTED&status=CANCELED${contactId ? `&contactId=${contactId}`: "" } `, null )
    const scheduledCanceledParams = contactId ? { sessionStatus: 'CANCELED', contactId } : { sessionStatus: 'CANCELED' };
    const scheduledCanceled = await api.get("/api/v1/my/sessions", scheduledCanceledParams);
    if (requestedCanceled && scheduledCanceled) {
      const demoOne = requestedCanceled?.requests;
      const demoTwo = scheduledCanceled?.sessions;
      dispatch(setSessions([...demoOne, ...demoTwo] as any));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSessionRequest = (data: any) => async (dispatch: any) => {
  const { requestId, status, contactId } = data;
  try {
    const response = await api.create(
      `/api/v1/sessions/request/${requestId}/${status}`,
      null
    );
    if (response) {
      toast.success("Request updated successfully", { autoClose: 2000 });
      dispatch(fetchRequestedSessions(contactId));
    }
  } catch (error: any) {
    toast.error(error.message);
    console.error(error);
  }
};

export const rejectRequestedSession = (data: any) => async (dispatch: any) => {
  const { requestId, reason, contactId } = data;
  try {
    const response = await api.create(
      `/api/v1/sessions/request/${requestId}/reject`,
      {
        reason: reason,
      }
    );
    if (response) {
      toast.success("Request declined successfully", { autoClose: 2000 });
      dispatch(fetchRequestedSessions(contactId));
    }
  } catch (error) {
    toast.error("Failed to update request", { autoClose: 2000 });
    console.error(error);
  }
};

export const cancelRequestedSession = (data: any) => async (dispatch: any) => {
  const { requestId, reason, contactId } = data;
  try {
    const response = await api.delete(`/api/v1/sessions/request/${requestId}`, {
      data: { reason: reason },
    });
    if (response) {
      toast.success("Request cancelled successfully", { autoClose: 2000 });
      dispatch(fetchRequestedSessions(contactId));
    }
  } catch (error) {
    toast.error("Failed to update request", { autoClose: 2000 });
    console.error(error);
  }
};

export const cancelScheduledSession = (data: any) => async (dispatch: any) => {
  const { requestId, reason, contactId } = data;
  try {
    const response = await api.delete(`/api/v1/sessions/${requestId}`, {
      data: { reason: reason },
    });
    if (response) {
      toast.success("Session cancelled successfully", { autoClose: 2000 });
      dispatch(fetchMySessions({ status: "SCHEDULED", contactId }));
    }
  } catch (error) {
    console.error(error);
  }
};

export const acceptRescheduleRequest = (data: any) => async (dispatch: any) => {
  const { requestId, contactId } = data;
  try {
    const response = await api.create(`/api/v1/sessions/${requestId}/reschedule/accept`,null);
    if (response) {
      toast.success("Rechedule request accepted successfully", { autoClose: 2000 });
      dispatch(fetchMySessions({ status: "SCHEDULED", contactId }));
    }
  } catch (error) {
    toast.error("Failed to accept reschedule request", { autoClose: 2000 });
    console.error(error);
  }
}