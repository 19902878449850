import React, { useEffect, useState } from "react";

// Redux
import {Link, useNavigate} from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert,
  Button,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// import images
import mentorshipBg from "../../assets/images/mentorship.png";
import logo from "../../assets/images/logo.png";
import lightlogo from "../../assets/images/logo-light.svg";
import GoogleIcon from "../../assets/images/google.svg";
import MicrosoftIcon from "../../assets/images/microsoft.svg";

//import thunk
import { loginUser, resetLoginMsgFlag } from "slices/auth/login/thunk";

import withRouter from "Components/Common/withRouter";
import { createSelector } from "reselect";
import { getPublicConfig } from "slices/thunk";
import { isEmpty } from "lodash";

const Login = (props: any) => {
  const [show, setShow] = useState(false);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  //meta title
  document.title = "Login | Mentorgain";

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (login) => ({
      error: login.error,
    })
  );

  const selectPublicConfigProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      publicConfig: global.publicConfig,
    })
  );

  const { error } = useSelector(selectProperties);
  const { publicConfig } = useSelector(selectPublicConfigProperties);

  // Form validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      dispatch(loginUser(values, navigate));
    },
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 3000);
    }

    if (isEmpty(publicConfig)) {
      dispatch(getPublicConfig());
    }
  }, [dispatch, error, validation, publicConfig]);

  const redirectToGoogle = () => {
    const subdomain = window.location.host.split(".")[0];
    window.location.assign(`${process.env.REACT_APP_AUTH_URL}/google/init/${subdomain}`);
  };

  const redirectToMicrosoft = () => {
    window.location.assign(`${process.env.REACT_APP_AUTH_URL}/microsoft/init`);
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Welcome Back to {publicConfig?.name} Mentoring
                        </h5>
                        <p>Sign in to continue to Mentorgain Dashboard.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={mentorshipBg} alt="" className="img-fluid pt-4"/>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="Mentorgain Logo" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>

                  {publicConfig?.enabledProviders && (
                    <>
                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <div className="p-2">
                          {publicConfig?.enabledProviders?.includes(
                            "google"
                          ) && (
                            <div className="d-grid">
                              <Button
                                className="btn btn-soft-primary btn-block"
                                onClick={redirectToGoogle}
                              >
                                <img
                                  src={GoogleIcon}
                                  alt="Continue with Google"
                                  className="mx-2"
                                  style={{ width: "16px" }}
                                />
                                Continue With Google
                              </Button>
                            </div>
                          )}

                          {publicConfig?.enabledProviders?.includes(
                            "microsoft"
                          ) && (
                            <div className="mt-3 d-grid">
                              <Button
                                className="btn btn-soft-primary btn-block"
                                onClick={redirectToMicrosoft}
                              >
                                <img
                                  src={MicrosoftIcon}
                                  alt="Continue with Microsoft"
                                  className="mx-2"
                                  style={{ width: "16px" }}
                                />
                                Continue With Microsoft
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="p-2">
                        <div className="separator">OR</div>
                      </div>
                    </>
                  )}

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <Label className="form-label">Email</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            !!(
                              validation.touched.username &&
                              validation.errors.username
                            )
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(
                                validation.touched.password &&
                                validation.errors.password
                              )
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="me-4 text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your
                          password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&apos;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Mentorgain</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
